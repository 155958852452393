export function isJSON(str: any) {
  if (typeof str == 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}
